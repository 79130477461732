import { Trans } from "@lingui/macro";
import { getWrappedToken } from "config/tokens";
import { approveTokens } from "domain/tokens";
import { isAddressZero } from "lib/legacy";
import { useState } from "react";
import { ImCheckboxUnchecked, ImSpinner2 } from "react-icons/im";

import "./ApproveTokenButton.scss";
import useWallet from "lib/wallets/useWallet";

type Props = {
  spenderAddress: string;
  tokenAddress: string;
  tokenSymbol: string;
  isApproved?: boolean;
  approveAmount?: bigint;
  customLabel?: string;
};

export function ApproveTokenButton(p: Props) {
  const { signer, chainId } = useWallet();
  const [isApproving, setIsApproving] = useState(false);
  const [isApproveSubmitted, setIsApproveSubmitted] = useState(false);

  function onApprove() {
    if (!chainId || isApproveSubmitted || p.isApproved) return;

    const wrappedToken = getWrappedToken(chainId);
    const tokenAddress = isAddressZero(p.tokenAddress) ? wrappedToken.address : p.tokenAddress;

    approveTokens({
      setIsApproving,
      signer,
      tokenAddress: tokenAddress,
      spender: p.spenderAddress,
      pendingTxns: [],
      setPendingTxns: () => null,
      infoTokens: {},
      chainId,
      approveAmount: p.approveAmount,
      onApproveSubmitted: () => setIsApproveSubmitted(true),
    });
  }

  const isLoading = isApproving || (isApproveSubmitted && !p.isApproved);

  return (
    <button className="mt-24 rounded-[5px] w-full h-40 button secondary center" onClick={onApprove}>
      {isLoading ? 'Approving...' : 'Approve'}
    </button>
  );
}
