import { ARBITRUM, BSС_MAINNET } from "./chains";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getIsSyntheticsSupported(chainId: number) {
  return true;
}

export function getIsV1Supported(chainId: number) {
  return [BSС_MAINNET, ARBITRUM].includes(chainId);
}
