/* eslint-disable @typescript-eslint/no-unused-vars */
import { Trans, t } from "@lingui/macro";
import { useMemo, useState } from "react";

import Modal from "components/Modal/Modal";
import Tooltip from "components/Tooltip/Tooltip";

import StakingPool from "abis/StakingPool.json"

import { SetPendingTransactions } from "domain/legacy";
import { ethers } from "ethers";

import useSWR from "swr";

import { getContract } from "config/contracts";

import { useConnectModal } from "@rainbow-me/rainbowkit";
import BuyInputSection from "components/BuyInputSection/BuyInputSection";
import SEO from "components/Common/SEO";
import ExternalLink from "components/ExternalLink/ExternalLink";
import PageTitle from "components/PageTitle/PageTitle";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import TooltipWithPortal from "components/Tooltip/TooltipWithPortal";
import { ApproveTokenButton } from "components/ApproveTokenButton/ApproveTokenButton";
import { getServerUrl } from "config/backend";
import { getIcon, getIcons } from "config/icons";
import useVestingData from "domain/vesting/useVestingData";
import { bigMath } from "lib/bigmath";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import {
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  parseValue,
} from "lib/numbers";
import { useTokensAllowanceData } from "domain/synthetics/tokens";
import { approveTokens } from "domain/tokens";
import { UncheckedJsonRpcSigner } from "lib/rpc/UncheckedJsonRpcSigner";
import { usePendingTxns } from "lib/usePendingTxns";
import useIsMetamaskMobile from "lib/wallets/useIsMetamaskMobile";
import useWallet from "lib/wallets/useWallet";
import "./StakeV2.css";
const { ZeroAddress } = ethers;
import { ImSpinner2 } from "react-icons/im";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";

function DepositModal(props: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  balance: bigint | undefined;
  value: string;
  apr: bigint | undefined;
  totalDeposited: string;
  setValue: (value: string) => void;
  chainId: number;
  asset: string;
  token: string;
  signer: UncheckedJsonRpcSigner | undefined;
  stakingAddress: string;
  setPendingTxns: SetPendingTransactions;
}) {
  const {
    isOpen,
    setIsOpen,
    chainId,
    balance,
    value,
    apr,
    totalDeposited,
    setValue,
    asset,
    token,
    signer,
    stakingAddress,
    setPendingTxns
  } = props;
  const [isDepositing, setIsDepositing] = useState(false);
  const isMetamaskMobile = useIsMetamaskMobile();
  const [isApproving, setIsApproving] = useState(false);
  const icons = getIcons(chainId);
  const { tokensAllowanceData } = useTokensAllowanceData(chainId, {
    spenderAddress: stakingAddress,
    tokenAddresses: [token],
  });
  const tokenAllowance = tokensAllowanceData?.[token];

  let amount = parseValue(value, 18);
  const needApproval =
    asset.toUpperCase() !== 'BNB' && stakingAddress !== ZeroAddress && tokenAllowance !== undefined && amount !== undefined && amount > tokenAllowance;

  const getError = () => {
    if (amount === undefined || amount === 0n) {
      return t`Enter an amount`;
    }
    if (balance !== undefined && amount > balance) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        signer,
        tokenAddress: token,
        spender: stakingAddress,
        chainId,
      });
      return;
    }
    setIsDepositing(true);
    const contract = new ethers.Contract(stakingAddress, StakingPool.abi, signer);

    if (asset.toUpperCase() === 'BNB') {
      callContract(chainId, contract, "depositETH", [ZeroAddress], {
        value: amount,
        sentMsg: `Deposit submitted!`,
        failMsg: `Deposit failed!`,
        successMsg: `Deposited!`,
        setPendingTxns,
      })
        .then(() => {
          setIsOpen(false);
        })
        .finally(() => {
          setIsDepositing(false);
        });
    } else {
      callContract(chainId, contract, "deposit", [token, amount, ZeroAddress], {
        sentMsg: `Deposit submitted!`,
        failMsg: `Deposit failed!`,
        successMsg: `Deposited!`,
        setPendingTxns,
      })
        .then(() => {
          setIsOpen(false);
        })
        .finally(() => {
          setIsDepositing(false);
        });
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving || needApproval) {
      return false;
    }
    if (isDepositing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving || needApproval) {
      return `Pending ${asset.toUpperCase()} approval`;
    }
    if (isDepositing) {
      return `Depositing...`;
    }
    return `Deposit`;
  };

  return (
    <Modal isVisible={isOpen} setIsVisible={setIsOpen} label={`Stake ${asset.toUpperCase()}`} className="non-scrollable">
      <div className="flex mb-4">Amount</div>
      <BuyInputSection
        topLeftLabel={`Deposit`}
        topRightLabel={t`Max`}
        topRightValue={formatAmount(balance, 18, 4, true)}
        onClickTopRightLabel={() => balance !== undefined && setValue(formatAmountFree(balance, 18, 18))}
        inputValue={value}
        onInputValueChange={(e) => setValue(e.target.value)}
        showMaxButton={false}
      >
        <div className="Stake-modal-icons">
          <img className="mr-5 icon h-22" height="22" src={icons[asset]} alt="Asset" />
          {asset.toUpperCase()}
        </div>
      </BuyInputSection>
      <div className="pt-8">Overview</div>
      <div className="mt-4 border border-[#4c535b] rounded-[5px] p-15 flex flex-col gap-8">
        <div className="flex justify-between">
          <div className="text-gray-300">Supply APY</div>
          <div>{formatAmount(apr, 2, 2, true)}%</div>
        </div>
        <div className="flex justify-between">
          <div className="text-gray-300">Total Deposited</div>
          <div>{totalDeposited}</div>
        </div>
      </div>

      {(needApproval || isApproving) ? (
        <ApproveTokenButton
          tokenAddress={token}
          spenderAddress={stakingAddress}
          tokenSymbol={asset.toUpperCase()}
          isApproved={!needApproval}
        />
      ) : (
        <button className="mt-24 rounded-[5px] w-full h-40 button secondary center" disabled={!isPrimaryEnabled()} onClick={onClickPrimary}>
          {getPrimaryText()}
        </button>
      )}
    </Modal>
  )
}

function UnlockModal(props: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  balance: bigint | undefined;
  value: string;
  setValue: (value: string) => void;
  chainId: number;
  asset: string;
  token: string;
  signer: UncheckedJsonRpcSigner | undefined;
  stakingAddress: string;
  setPendingTxns: SetPendingTransactions;
}) {
  const {
    isOpen,
    setIsOpen,
    chainId,
    balance,
    value,
    setValue,
    asset,
    token,
    signer,
    stakingAddress,
    setPendingTxns
  } = props;
  const [isUnlocking, setIsUnlocking] = useState(false);
  const icons = getIcons(chainId);

  let amount = parseValue(value, 18);

  const getError = () => {
    if (amount === undefined || amount === 0n) {
      return t`Enter an amount`;
    }
    if (balance !== undefined && amount > balance) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsUnlocking(true);
    const contract = new ethers.Contract(stakingAddress, StakingPool.abi, signer);

    callContract(chainId, contract, "unlock", [token, amount], {
      sentMsg: t`Unlock submitted!`,
      failMsg: t`Unlock failed!`,
      successMsg: t`Unlocked!`,
      setPendingTxns,
    })
      .then(() => {
        setIsOpen(false);
      })
      .finally(() => {
        setIsUnlocking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnlocking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnlocking) {
      return t`Unlocking...`;
    }
    return t`Unlock`;
  };

  return (
    <Modal isVisible={isOpen} setIsVisible={setIsOpen} label={`Unlock ${asset.toUpperCase()}`} className="non-scrollable">
      <div className="flex mb-4">Amount</div>
      <BuyInputSection
        topLeftLabel={t`Unlock`}
        topRightLabel={t`Max`}
        topRightValue={formatAmount(balance, 18, 4, true)}
        onClickTopRightLabel={() => balance !== undefined && setValue(formatAmountFree(balance, 18, 18))}
        inputValue={value}
        onInputValueChange={(e) => setValue(e.target.value)}
        showMaxButton={false}
      >
        <div className="Stake-modal-icons">
          <img className="mr-5 icon h-22" height="22" src={icons[asset]} alt="Asset" />
          {asset.toUpperCase()}
        </div>
      </BuyInputSection>
      <div className="pt-8">Overview</div>
      <div className="mt-4 border border-[#4c535b] rounded-[5px] p-15 flex flex-col gap-8">
        <div className="flex justify-between">
          <div className="text-gray-300">Lock Period</div>
          <div>5 Mins</div>
        </div>
        <div className="flex">
          <div className="text-gray-300">You can withdraw after lock period.</div>
        </div>
      </div>
      <button className="mt-24 rounded-[5px] w-full h-40 button secondary center" disabled={!isPrimaryEnabled()} onClick={onClickPrimary}>
        {getPrimaryText()}
      </button>
    </Modal>
  )
}

export default function StakeV2() {
  const { active, signer, account } = useWallet();
  const { chainId } = useChainId();
  const { openConnectModal } = useConnectModal();

  const [, setPendingTxns] = usePendingTxns();

  const stakingAddress = getContract(chainId, "Staking");

  const { data: poolInfo } = useSWR(
    [`StakeV2:getPoolInfo:${active}`, chainId, stakingAddress, "getPoolInfo"],
    {
      fetcher: contractFetcher(signer, StakingPool),
    }
  );

  const { data: userInfo } = useSWR(
    [`StakeV2:getUserInfo:${active}`, chainId, stakingAddress, "getUserInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, StakingPool),
    }
  );

  const { data: userBalance } = useSWR(
    [`StakeV2:getUserWalletBalance:${active}`, chainId, stakingAddress, "getUserWalletBalance", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, StakingPool),
    }
  );

  let items = [
    { chainId: 56, asset: 'bnb' },
    { chainId: 56, asset: 'usdt' },
    { chainId: 56, asset: 'usdc' },
    { chainId: 56, asset: 'btc' },
  ] as {
    chainId: number,
    asset: string,
    apr: bigint,
    stakeAmt: bigint,
    token: string,
    lockAmt: bigint,
    reqWithdrawAmt: bigint,
    unlockTime: bigint,
    balance: bigint,
    decimals: number
  }[];

  if ((poolInfo && poolInfo.length > 0) || (userInfo && userInfo.length > 0) || (userBalance && userBalance.length > 0)) {
    items = items.map((list, index) => {
      let updatedItem = { ...list };

      if (poolInfo && poolInfo.length > 0) {
        updatedItem = { ...updatedItem, ...poolInfo[index] };
      }

      if (userInfo && userInfo.length > 0) {
        updatedItem = { ...updatedItem, ...userInfo[index]};
      }

      if (userBalance && userBalance.length > 0) {
        updatedItem = { ...updatedItem, decimals: userBalance[index].decimals, balance: userBalance[index].amount };
      }

      return updatedItem;
    });
  }
  console.log('>>>>>', userInfo)

  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false)
  const [isUnlockModalOpen, setIsUnlockModalOpen] = useState(false)
  const [openItems, setOpenItems] = useState(Array(items.length).fill(false));
  const [inputAmount, setInputAmount] = useState<string>('')
  const [selectedAsset, setSelectedAsset] = useState<string>('eth')
  const [selectedToken, setSelectedToken] = useState('')
  const [apr, setApr] = useState()
  const [balance, setBalance] = useState()
  const [myStaked, setMyStaked] = useState()
  const [totalDeposited, setTotalDeposited] = useState('')
  const [isWithdrawing, setIsWithdrawing] = useState(false)

  const withdraw = (token: string) => {
    setIsWithdrawing(true)
    const contract = new ethers.Contract(stakingAddress, StakingPool.abi, signer);
    callContract(chainId, contract, 'withdraw', [token], {
      sentMsg: t`Withdraw submitted!`,
      failMsg: t`Withdraw failed.`,
      successMsg: t`Withdraw completed!`,
      setPendingTxns,
    })
      .finally(() => {
        setIsWithdrawing(false);
      })
  }

  const openDepositModal = (item: any) => {
    setInputAmount('');
    setSelectedAsset(item.asset)
    setSelectedToken(item.token)
    setApr(item.apr)
    setBalance(item.balance)
    setTotalDeposited(formatAmount(item.stakeAmt, item.decimals, 2, true))
    setIsDepositModalOpen(true)
  }

  const openUnlockModal = (item: any) => {
    setInputAmount('');
    setSelectedAsset(item.asset)
    setSelectedToken(item.token)
    setMyStaked(item.lockAmt)
    setIsUnlockModalOpen(true)
  }

  const toggleCollapse = (index: number) => {
    const newOpenItems = [...openItems];
    newOpenItems[index] = !newOpenItems[index];
    setOpenItems(newOpenItems);
  };

  return (
    <div className="default-container page-layout">
      <DepositModal
        isOpen={isDepositModalOpen}
        setIsOpen={setIsDepositModalOpen}
        chainId={chainId}
        balance={balance}
        value={inputAmount}
        setValue={setInputAmount}
        asset={selectedAsset}
        apr={apr}
        totalDeposited={totalDeposited}
        token={selectedToken}
        signer={signer}
        stakingAddress={stakingAddress}
        setPendingTxns={setPendingTxns}
      />
      <UnlockModal
        isOpen={isUnlockModalOpen}
        setIsOpen={setIsUnlockModalOpen}
        chainId={chainId}
        balance={myStaked}
        value={inputAmount}
        setValue={setInputAmount}
        asset={selectedAsset}
        token={selectedToken}
        signer={signer}
        stakingAddress={stakingAddress}
        setPendingTxns={setPendingTxns}
      />
      <div>
        <PageTitle
          title={`Staking`}
        />
        <div className="flex flex-col gap-8">
          {items.map((item, index) => (
            <div key={index} className="w-full rounded-[10px] p-6 border border-[#333940] bg-[#20222480] group relative z-10 overflow-hidden transition-transform ease-in-out hover:scale-[1.01]">
              <div className="flex">
                <div
                  className="grow w-full items-center grid grid-cols-4 sm:grid-cols-8 "
                >
                  <div className="flex gap-8 items-center px-8 py-10">
                    <img src={getIcon(item.chainId, item.asset)} className="" />
                    <span className="uppercase sm:block hidden">{item.asset}</span>
                  </div>
                  <div className="flex flex-col gap-2 items-start px-8 py-10">
                    <span className="text-[10px] text-[#bcbdbf]">APY</span>
                    <span className="">{formatAmount(item.apr, 2, 2, true)}%</span>
                  </div>
                  <div className="sm:flex hidden flex-col gap-2 items-start px-8 py-10">
                    <span className="text-[10px] text-[#bcbdbf]">Wallet Balance</span>
                    <span className="">{formatAmount(item.balance, item.decimals, 2, true)}</span>
                  </div>
                  <div className="sm:flex hidden flex-col gap-2 items-start px-8 py-10">
                    <span className="text-[10px] text-[#bcbdbf]">My Staked</span>
                    <span className="">{formatAmount(item.lockAmt, item.decimals, 2, true)}</span>
                  </div>
                  <div className="sm:flex hidden flex-col gap-2 items-start px-8 py-10">
                    <span className="text-[10px] text-[#bcbdbf]">Total Staked</span>
                    <span className="">{formatAmount(item.stakeAmt, item.decimals, 2, true)}</span>
                  </div>
                  <div className="sm:flex hidden flex-col gap-2 items-start px-8 py-10">
                    <span className="text-[10px] text-[#bcbdbf]">Withdrawable</span>
                    <span className="">{formatAmount(item.reqWithdrawAmt, item.decimals, 2, true)}</span>
                    {item.reqWithdrawAmt > 0n && <span className="text-[10px] text-[#bcbdbf]">{Number(item.unlockTime + 300n)  < Date.now() / 1000 ? 'Unlocked' : `Unlocked in ${Math.ceil((Number(item.unlockTime + 300n) - Date.now() / 1000) / 3600)} hours`}</span>}
                  </div>
                  <div className="flex px-8 py-10 col-span-2 justify-center">
                    <button onClick={() => openDepositModal(item)} className="bg-[#292c3a] hover:bg-[#50577e99] text-[12px] sm:text-[14px] border border-[#333940] px-4 py-12 sm:px-12 rounded-l-[10px]">Deposit</button>
                    {(item.reqWithdrawAmt === 0n || !item.reqWithdrawAmt) && <button onClick={() => openUnlockModal(item)} disabled={item.lockAmt === 0n} className="bg-[#292c3a] disabled:bg-[#222] disabled:text-[#aaa] hover:bg-[#50577e99] text-[12px] sm:text-[14px] border border-[#333940] px-4 py-12 sm:px-12 rounded-r-[10px]">Unlock</button>}
                    {item.reqWithdrawAmt > 0n && <button onClick={() => withdraw(item.token)} disabled={(Number(item.unlockTime + 300n) > Date.now() / 1000)} className="bg-[#292c3a] hover:bg-[#50577e99] disabled:bg-[#222] disabled:text-[#aaa] text-[12px] sm:text-[14px] border border-[#333940] px-4 py-12 sm:px-12 rounded-r-[10px] min-w-48 sm:min-w-70 flex justify-center items-center">{isWithdrawing ? <ImSpinner2 className="spin text-white" /> : 'Withdraw'}</button>}
                  </div>
                </div>
                <button
                  onClick={() => toggleCollapse(index)}
                  className="flex grow-0 items-center justify-end"
                >
                  <svg className={`w-30 h-30 fill-white sm:hidden block ${openItems[index] ? 'rotate-180' : 'rotate-0'}`} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                  </svg>
                </button>
              </div>
              <div
                className={`mt-2 transition-all duration-300 ease-in-out ${openItems[index] ? "opacity-100" : "max-h-0 opacity-0 overflow-hidden"
                  }`}
              >
                <div className="grid grid-cols-2">
                  <div className="flex sm:hidden flex-col gap-2 items-center px-8 py-10">
                    <span className="text-[10px] text-[#bcbdbf]">Balance</span>
                    <span className="">{formatAmount(item.balance, item.decimals, 2, true)}</span>
                  </div>
                  <div className="flex sm:hidden flex-col gap-2 items-center px-8 py-10">
                    <span className="text-[10px] text-[#bcbdbf]">My Staked</span>
                    <span className="">{formatAmount(item.lockAmt, item.decimals, 2, true)}</span>
                  </div>
                  <div className="flex sm:hidden flex-col gap-2 items-center px-8 py-10">
                    <span className="text-[10px] text-[#bcbdbf]">Total Staked</span>
                    <span className="">{formatAmount(item.stakeAmt, item.decimals, 2, true)}</span>
                  </div>
                  <div className="flex sm:hidden flex-col gap-2 items-center px-8 py-10">
                    <span className="text-[10px] text-[#bcbdbf]">Withdrawable</span>
                    <span className="">{formatAmount(item.reqWithdrawAmt, item.decimals, 2, true)}</span>
                    {item.reqWithdrawAmt > 0n && <span className="text-[10px] text-[#bcbdbf]">{Number(item.unlockTime + 300n)  < Date.now() / 1000 ? 'Unlocked' : `Unlocked in ${Math.ceil((Number(item.unlockTime + 300n) - Date.now() / 1000) / 3600)} hours`}</span>}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
