/* eslint-disable @typescript-eslint/no-unused-vars */
import { Trans, t } from "@lingui/macro";
import { useMemo, useState } from "react";

import Modal from "components/Modal/Modal";
import Tooltip from "components/Tooltip/Tooltip";

import BorrowingPool from "abis/Borrowing.json"
import StakingPool from "abis/StakingPool.json"

import { SetPendingTransactions } from "domain/legacy";
import { ethers } from "ethers";

import useSWR from "swr";

import { getContract } from "config/contracts";

import { useConnectModal } from "@rainbow-me/rainbowkit";
import BuyInputSection from "components/BuyInputSection/BuyInputSection";
import SEO from "components/Common/SEO";
import ExternalLink from "components/ExternalLink/ExternalLink";
import PageTitle from "components/PageTitle/PageTitle";
import StatsTooltipRow from "components/StatsTooltip/StatsTooltipRow";
import TooltipWithPortal from "components/Tooltip/TooltipWithPortal";
import { ApproveTokenButton } from "components/ApproveTokenButton/ApproveTokenButton";
import { getServerUrl } from "config/backend";
import { getIcon, getIcons } from "config/icons";
import useVestingData from "domain/vesting/useVestingData";
import { bigMath } from "lib/bigmath";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import {
  formatAmount,
  formatAmountFree,
  formatKeyAmount,
  parseValue,
} from "lib/numbers";
import { useTokensAllowanceData } from "domain/synthetics/tokens";
import { approveTokens } from "domain/tokens";
import { UncheckedJsonRpcSigner } from "lib/rpc/UncheckedJsonRpcSigner";
import { usePendingTxns } from "lib/usePendingTxns";
import useIsMetamaskMobile from "lib/wallets/useIsMetamaskMobile";
import useWallet from "lib/wallets/useWallet";
import "./StakeV2.css";
const { ZeroAddress } = ethers;
import { ImSpinner2 } from "react-icons/im";
import { PLACEHOLDER_ACCOUNT } from "lib/legacy";

function AddModal(props: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  balance: bigint | undefined;
  value: string;
  collateralAmt: string;
  setValue: (value: string) => void;
  chainId: number;
  asset: string;
  token: string;
  signer: UncheckedJsonRpcSigner | undefined;
  borrowingAddress: string;
  setPendingTxns: SetPendingTransactions;
}) {
  const {
    isOpen,
    setIsOpen,
    chainId,
    balance,
    value,
    collateralAmt,
    setValue,
    asset,
    token,
    signer,
    borrowingAddress,
    setPendingTxns
  } = props;
  const [isAdding, setIsAdding] = useState(false);
  const isMetamaskMobile = useIsMetamaskMobile();
  const icons = getIcons(chainId);

  let amount = parseValue(value, 18);

  const getError = () => {
    if (amount === undefined || amount === 0n) {
      return t`Enter an amount`;
    }
    if (balance !== undefined && amount > balance) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsAdding(true);
    const contract = new ethers.Contract(borrowingAddress, BorrowingPool.abi, signer);

    callContract(chainId, contract, "addCollateral", [token, amount], {
      sentMsg: `Adding collateral submitted!`,
      failMsg: `Adding collateral failed!`,
      successMsg: `Added collateral!`,
      setPendingTxns,
    })
      .then(() => {
        setIsOpen(false);
      })
      .finally(() => {
        setIsAdding(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isAdding) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isAdding) {
      return `Adding...`;
    }
    return `Add`;
  };

  return (
    <Modal isVisible={isOpen} setIsVisible={setIsOpen} label={`Add ${asset.toUpperCase()}`} className="non-scrollable">
      <div className="flex mb-4">Amount</div>
      <BuyInputSection
        topLeftLabel={`Add`}
        topRightLabel={t`Max`}
        topRightValue={formatAmount(balance, 18, 4, true)}
        onClickTopRightLabel={() => balance !== undefined && setValue(formatAmountFree(balance, 18, 18))}
        inputValue={value}
        onInputValueChange={(e) => setValue(e.target.value)}
        showMaxButton={false}
      >
        <div className="Stake-modal-icons">
          <img className="mr-5 icon h-22" height="22" src={icons[asset]} alt="esGMX" />
          {asset.toUpperCase()}
        </div>
      </BuyInputSection>
      <div className="pt-8">Overview</div>
      <div className="mt-4 border border-[#4c535b] rounded-[5px] p-15 flex flex-col gap-8">
        <div className="flex justify-between">
          <div className="text-gray-300">Collateral Amount</div>
          <div>{collateralAmt}</div>
        </div>
      </div>
      <button className="mt-24 rounded-[5px] w-full h-40 button secondary center" disabled={!isPrimaryEnabled()} onClick={onClickPrimary}>
        {getPrimaryText()}
      </button>
    </Modal>
  )
}

function RemoveModal(props: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  balance: bigint | undefined;
  value: string;
  setValue: (value: string) => void;
  chainId: number;
  asset: string;
  token: string;
  signer: UncheckedJsonRpcSigner | undefined;
  borrowingAddress: string;
  setPendingTxns: SetPendingTransactions;
}) {
  const {
    isOpen,
    setIsOpen,
    chainId,
    balance,
    value,
    setValue,
    asset,
    token,
    signer,
    borrowingAddress,
    setPendingTxns
  } = props;
  const [isRemoving, setIsRemoving] = useState(false);
  const icons = getIcons(chainId);

  let amount = parseValue(value, 18);

  const getError = () => {
    if (amount === undefined || amount === 0n) {
      return t`Enter an amount`;
    }
    if (balance !== undefined && amount > balance) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsRemoving(true);
    const contract = new ethers.Contract(borrowingAddress, BorrowingPool.abi, signer);

    callContract(chainId, contract, "removeCollateral", [token, amount], {
      sentMsg: `Removing collateral submitted!`,
      failMsg: `Removing collateral failed!`,
      successMsg: `Removed!`,
      setPendingTxns,
    })
      .then(() => {
        setIsOpen(false);
      })
      .finally(() => {
        setIsRemoving(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isRemoving) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isRemoving) {
      return t`Removing...`;
    }
    return t`Remove`;
  };

  return (
    <Modal isVisible={isOpen} setIsVisible={setIsOpen} label={`Remove ${asset.toUpperCase()}`} className="non-scrollable">
      <div className="flex mb-4">Amount</div>
      <BuyInputSection
        topLeftLabel={t`Remove`}
        topRightLabel={t`Max`}
        topRightValue={formatAmount(balance, 18, 4, true)}
        onClickTopRightLabel={() => balance !== undefined && setValue(formatAmountFree(balance, 18, 18))}
        inputValue={value}
        onInputValueChange={(e) => setValue(e.target.value)}
        showMaxButton={false}
      >
        <div className="Stake-modal-icons">
          <img className="mr-5 icon h-22" height="22" src={icons[asset]} alt="esGMX" />
          {asset.toUpperCase()}
        </div>
      </BuyInputSection>
      <button className="mt-24 rounded-[5px] w-full h-40 button secondary center" disabled={!isPrimaryEnabled()} onClick={onClickPrimary}>
        {getPrimaryText()}
      </button>
    </Modal>
  )
}

function BorrowModal(props: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  balance: bigint | undefined;
  value: string;
  apr: string;
  setValue: (value: string) => void;
  chainId: number;
  asset: string;
  token: string;
  signer: UncheckedJsonRpcSigner | undefined;
  borrowingAddress: string;
  setPendingTxns: SetPendingTransactions;
}) {
  const {
    isOpen,
    setIsOpen,
    chainId,
    balance,
    value,
    apr,
    setValue,
    asset,
    token,
    signer,
    borrowingAddress,
    setPendingTxns
  } = props;
  const [isBorrowing, setIsBorrowing] = useState(false);
  const isMetamaskMobile = useIsMetamaskMobile();
  const icons = getIcons(chainId);

  let amount = parseValue(value, 18);

  const getError = () => {
    if (amount === undefined || amount === 0n) {
      return t`Enter an amount`;
    }
    if (balance !== undefined && amount > balance) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    setIsBorrowing(true);
    const contract = new ethers.Contract(borrowingAddress, BorrowingPool.abi, signer);

    callContract(chainId, contract, "borrow", [token, amount], {
      sentMsg: `Borrowing submitted!`,
      failMsg: `Borrowing failed!`,
      successMsg: `Borrowed!`,
      setPendingTxns,
    })
      .then(() => {
        setIsOpen(false);
      })
      .finally(() => {
        setIsBorrowing(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isBorrowing) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isBorrowing) {
      return `Borrowing...`;
    }
    return `Borrow`;
  };

  return (
    <Modal isVisible={isOpen} setIsVisible={setIsOpen} label={`Add ${asset.toUpperCase()}`} className="non-scrollable">
      <div className="flex mb-4">Amount</div>
      <BuyInputSection
        topLeftLabel={`Borrow`}
        topRightLabel={t`Max`}
        topRightValue={formatAmount(balance, 18, 4, true)}
        onClickTopRightLabel={() => balance !== undefined && setValue(formatAmountFree(balance, 18, 18))}
        inputValue={value}
        onInputValueChange={(e) => setValue(e.target.value)}
        showMaxButton={false}
      >
        <div className="Stake-modal-icons">
          <img className="mr-5 icon h-22" height="22" src={icons[asset]} alt="esGMX" />
          {asset.toUpperCase()}
        </div>
      </BuyInputSection>
      <div className="pt-8">Overview</div>
      <div className="mt-4 border border-[#4c535b] rounded-[5px] p-15 flex flex-col gap-8">
        <div className="flex justify-between">
          <div className="text-gray-300">APY</div>
          <div>{apr}%</div>
        </div>
      </div>
      <button className="mt-24 rounded-[5px] w-full h-40 button secondary center" disabled={!isPrimaryEnabled()} onClick={onClickPrimary}>
        {getPrimaryText()}
      </button>
    </Modal>
  )
}

function RepayModal(props: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  balance: bigint | undefined;
  value: string;
  apr: string;
  setValue: (value: string) => void;
  chainId: number;
  asset: string;
  token: string;
  signer: UncheckedJsonRpcSigner | undefined;
  borrowingAddress: string;
  setPendingTxns: SetPendingTransactions;
}) {
  const {
    isOpen,
    setIsOpen,
    chainId,
    balance,
    value,
    apr,
    setValue,
    asset,
    token,
    signer,
    borrowingAddress,
    setPendingTxns
  } = props;
  const [isRepaying, setIsRepaying] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const isMetamaskMobile = useIsMetamaskMobile();
  const icons = getIcons(chainId);
  const { tokensAllowanceData } = useTokensAllowanceData(chainId, {
    spenderAddress: borrowingAddress,
    tokenAddresses: [token],
  });
  const tokenAllowance = tokensAllowanceData?.[token];

  let amount = parseValue(value, 18);

  const needApproval =
  asset.toUpperCase() !== 'BNB' && borrowingAddress !== ZeroAddress && tokenAllowance !== undefined && amount !== undefined && amount > tokenAllowance;

  const getError = () => {
    if (amount === undefined || amount === 0n) {
      return t`Enter an amount`;
    }
    if (balance !== undefined && amount > balance) {
      return t`Max amount exceeded`;
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        signer,
        tokenAddress: token,
        spender: borrowingAddress,
        chainId,
      });
      return;
    }
    setIsRepaying(true);
    const contract = new ethers.Contract(borrowingAddress, BorrowingPool.abi, signer);

    if (asset.toUpperCase() === 'BNB') {
      callContract(chainId, contract, "repayETH", [], {
        value: amount,
        sentMsg: `Repaying submitted!`,
        failMsg: `Repaying failed!`,
        successMsg: `Repayed!`,
        setPendingTxns,
      })
        .then(() => {
          setIsOpen(false);
        })
        .finally(() => {
          setIsRepaying(false);
        });
    } else {
      callContract(chainId, contract, "repay", [token, amount], {
        sentMsg: `Repaying submitted!`,
        failMsg: `Repaying failed!`,
        successMsg: `Repayed!`,
        setPendingTxns,
      })
        .then(() => {
          setIsOpen(false);
        })
        .finally(() => {
          setIsRepaying(false);
        });
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving || needApproval) {
      return false;
    }
    if (isRepaying) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving || needApproval) {
      return `Pending ${asset.toUpperCase()} approval`;
    }
    if (isRepaying) {
      return `Repaying...`;
    }
    return `Repay`;
  };

  return (
    <Modal isVisible={isOpen} setIsVisible={setIsOpen} label={`Add ${asset.toUpperCase()}`} className="non-scrollable">
      <div className="flex mb-4">Amount</div>
      <BuyInputSection
        topLeftLabel={`Repay`}
        topRightLabel={t`Max`}
        topRightValue={formatAmount(balance, 18, 4, true)}
        onClickTopRightLabel={() => balance !== undefined && setValue(formatAmountFree(balance, 18, 18))}
        inputValue={value}
        onInputValueChange={(e) => setValue(e.target.value)}
        showMaxButton={false}
      >
        <div className="Stake-modal-icons">
          <img className="mr-5 icon h-22" height="22" src={icons[asset]} alt="esGMX" />
          {asset.toUpperCase()}
        </div>
      </BuyInputSection>
      <div className="pt-8">Overview</div>
      <div className="mt-4 border border-[#4c535b] rounded-[5px] p-15 flex flex-col gap-8">
        <div className="flex justify-between">
          <div className="text-gray-300">APY</div>
          <div>{apr}%</div>
        </div>
      </div>
      {(needApproval || isApproving) ? (
        <ApproveTokenButton
          tokenAddress={token}
          spenderAddress={borrowingAddress}
          tokenSymbol={asset.toUpperCase()}
          isApproved={!needApproval}
        />
      ) : (
        <button className="mt-24 rounded-[5px] w-full h-40 button secondary center" disabled={!isPrimaryEnabled()} onClick={onClickPrimary}>
          {getPrimaryText()}
        </button>
      )}
    </Modal>
  )
}

export default function Borrowing() {
  const { active, signer, account } = useWallet();
  const { chainId } = useChainId();
  const { openConnectModal } = useConnectModal();

  const [, setPendingTxns] = usePendingTxns();

  const borrowingAddress = getContract(chainId, "Borrowing");

  const stakingAddress = getContract(chainId, "Staking");

  const { data: poolInfo } = useSWR(
    [`Borrowing:getAssetsInfo:${active}`, chainId, borrowingAddress, "getAssetsInfo"],
    {
      fetcher: contractFetcher(signer, BorrowingPool),
    }
  );

  const { data: borrowableInfo } = useSWR(
    [`Borrowing:getBorrowableInfo:${active}`, chainId, borrowingAddress, "getBorrowableInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, BorrowingPool),
    }
  );

  const { data: borrowInfo } = useSWR(
    [`Borrowing:getBorrowInfo:${active}`, chainId, borrowingAddress, "getBorrowInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, BorrowingPool),
    }
  );

  const { data: borrowLimitInfo } = useSWR(
    [`Borrowing:getCollateralRemoveLimit:${active}`, chainId, borrowingAddress, "getCollateralRemoveLimit", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, BorrowingPool),
    }
  );

  const { data: userInfo } = useSWR(
    [`Borrowing:getUserInfo:${active}`, chainId, stakingAddress, "getUserInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(signer, StakingPool),
    }
  );

  let items = [
    { chainId: 56, asset: 'bnb', decimals: 18 },
    { chainId: 56, asset: 'usdt', decimals: 18 },
    { chainId: 56, asset: 'usdc', decimals: 18 },
    { chainId: 56, asset: 'btc', decimals: 8 },
  ] as {
    chainId: number,
    decimals: number,
    asset: string,
    token: string,
    apr: bigint,
    amount: bigint,
    amountUSD: bigint,
    borrowAmt: bigint,
    borrowAmtUSD: bigint,
  }[];

  if ((poolInfo && poolInfo.length > 0) || (borrowableInfo && borrowableInfo.length > 0) || (borrowInfo && borrowInfo.length > 0)) {
    items = items.map((list, index) => {
      let updatedItem = { ...list };

      if (poolInfo && poolInfo.length > 0) {
        updatedItem = { ...updatedItem, ...poolInfo[index] };
      }

      if (borrowableInfo && borrowableInfo.length > 0) {
        updatedItem = { ...updatedItem, ...borrowableInfo[index] };
      }

      if (borrowInfo && borrowInfo.length > 0) {
        updatedItem = { ...updatedItem, borrowAmt: borrowInfo[index].amount, borrowAmtUSD: borrowInfo[index].amountUSD };
      }

      return updatedItem;
    });
  }

  let stakedList = [
    { chainId: 56, asset: 'bnb', decimals: 18 },
    { chainId: 56, asset: 'usdt', decimals: 18 },
    { chainId: 56, asset: 'usdc', decimals: 18 },
    { chainId: 56, asset: 'btc', decimals: 8 },
  ] as {
    chainId: number,
    decimals: number,
    asset: string,
    token: string,
    lockAmt: bigint,
    borrowCollateralAmt: bigint,
    rmvLimit: bigint
  }[];
  if ((userInfo && userInfo.length > 0) || (borrowLimitInfo && borrowLimitInfo.length > 0)) {
    stakedList = stakedList.map((list, index) => {
      let updateList = { ...list }
      if (userInfo && userInfo.length > 0) {
        updateList = { ...updateList, ...userInfo[index] }
      }

      if (borrowLimitInfo && borrowLimitInfo.length > 0) {
        updateList = { ...updateList, rmvLimit: borrowLimitInfo[index].amount}
      }
      return updateList
    }).filter(item => item.lockAmt > 0n)
  }
  // console.log('>>>>', stakedList, userInfo, poolInfo)

  const [isaddModalOpen, setIsAddModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [isBorrowModalOpen, setIsBorrowModalOpen] = useState(false)
  const [isRepayModalOpen, setIsRepayModalOpen] = useState(false)
  const [inputAmount, setInputAmount] = useState<string>('')
  const [selectedAsset, setSelectedAsset] = useState<string>('eth')
  const [selectedToken, setSelectedToken] = useState('')
  const [balance, setBalance] = useState()
  const [apr, setApr] = useState('')
  const [collateralAmt, setCollateralAmt] = useState('')
  const [isWithdrawing, setIsWithdrawing] = useState(false)

  const withdraw = (token: string) => {
    setIsWithdrawing(true)
    const contract = new ethers.Contract(borrowingAddress, BorrowingPool.abi, signer);
    callContract(chainId, contract, 'withdraw', [token], {
      sentMsg: t`Withdraw submitted!`,
      failMsg: t`Withdraw failed.`,
      successMsg: t`Withdraw completed!`,
      setPendingTxns,
    })
      .finally(() => {
        setIsWithdrawing(false);
      })
  }

  const openAddModal = (item: any) => {
    setInputAmount('');
    setSelectedAsset(item.asset)
    setSelectedToken(item.token)
    setBalance(item.lockAmt)
    setCollateralAmt(formatAmount(item.borrowCollateralAmt, item.decimals, 2, true))
    setIsAddModalOpen(true)
  }

  const openRemoveModal = (item: any) => {
    setInputAmount('');
    setSelectedAsset(item.asset)
    setSelectedToken(item.token)
    setBalance(item.rmvLimit)
    setIsRemoveModalOpen(true)
  }

  const openBorrowModal = (item: any) => {
    setInputAmount('');
    setSelectedAsset(item.asset)
    setSelectedToken(item.token)
    setBalance(item.amount)
    setApr(formatAmount(item.apr, 2, 2, true))
    setCollateralAmt(formatAmount(item.borrowCollateralAmt, item.decimals, 2, true))
    setIsBorrowModalOpen(true)
  }

  const openRepayModal = (item: any) => {
    setInputAmount('');
    setSelectedAsset(item.asset)
    setSelectedToken(item.token)
    setBalance(item.borrowAmt)
    setApr(formatAmount(item.apr, 2, 2, true))
    setCollateralAmt(formatAmount(item.borrowCollateralAmt, item.decimals, 2, true))
    setIsRepayModalOpen(true)
  }

  return (
    <div className="default-container page-layout">
      <AddModal
        isOpen={isaddModalOpen}
        setIsOpen={setIsAddModalOpen}
        chainId={chainId}
        balance={balance}
        value={inputAmount}
        setValue={setInputAmount}
        asset={selectedAsset}
        collateralAmt={collateralAmt}
        token={selectedToken}
        signer={signer}
        borrowingAddress={borrowingAddress}
        setPendingTxns={setPendingTxns}
      />
      <RemoveModal
        isOpen={isRemoveModalOpen}
        setIsOpen={setIsRemoveModalOpen}
        chainId={chainId}
        balance={balance}
        value={inputAmount}
        setValue={setInputAmount}
        asset={selectedAsset}
        token={selectedToken}
        signer={signer}
        borrowingAddress={borrowingAddress}
        setPendingTxns={setPendingTxns}
      />
      <BorrowModal
        isOpen={isBorrowModalOpen}
        setIsOpen={setIsBorrowModalOpen}
        chainId={chainId}
        balance={balance}
        value={inputAmount}
        setValue={setInputAmount}
        asset={selectedAsset}
        apr={apr}
        token={selectedToken}
        signer={signer}
        borrowingAddress={borrowingAddress}
        setPendingTxns={setPendingTxns}
      />
      <RepayModal
        isOpen={isRepayModalOpen}
        setIsOpen={setIsRepayModalOpen}
        chainId={chainId}
        balance={balance}
        value={inputAmount}
        setValue={setInputAmount}
        asset={selectedAsset}
        apr={apr}
        token={selectedToken}
        signer={signer}
        borrowingAddress={borrowingAddress}
        setPendingTxns={setPendingTxns}
      />
      <div>
        <PageTitle
          title={`Borrowing`}
        />
        <div className="flex flex-col lg:flex-row gap-10">
          <div className="flex flex-col gap-8 w-full lg:w-1/2">
            <div className="text-[20px]">Assets to Supply</div>
            {stakedList?.length === 0 && (
              <div className="w-full h-[77px] flex items-center justify-center rounded-[10px] p-6 border border-[#333940] bg-[#20222480] group relative z-10 overflow-hidden transition-transform ease-in-out hover:scale-[1.01]">
                Nothing staked yet
              </div>
            )}
            {stakedList.map((item, index) => (
              <div key={index} className="w-full rounded-[10px] p-6 border border-[#333940] bg-[#20222480] group relative z-10 overflow-hidden transition-transform ease-in-out hover:scale-[1.01]">
                <div className="flex">
                  <div
                    className="grow w-full items-center grid grid-cols-4"
                  >
                    <div className="flex gap-8 items-center px-8 py-10">
                      <img src={getIcon(item.chainId, item.asset)} className="" />
                      <span className="uppercase sm:block hidden">{item.asset}</span>
                    </div>
                    <div className="flex flex-col gap-2 items-start px-8 py-10">
                      <span className="text-[10px] text-[#bcbdbf]">Staked Amount</span>
                      <span className="">{formatAmount(item.lockAmt, item.decimals, 2, true)}</span>
                    </div>
                    <div className="flex flex-col gap-2 items-start px-8 py-10">
                      <span className="text-[10px] text-[#bcbdbf]">Collateral Amount</span>
                      <span className="">{formatAmount(item.borrowCollateralAmt, item.decimals, 2, true)}</span>
                    </div>
                    <div className="flex px-8 py-10 justify-end sm:justify-center">
                      <button onClick={() => openAddModal(item)} className="bg-[#292c3a] hover:bg-[#50577e99] text-[12px] sm:text-[14px] border border-[#333940] px-4 py-12 sm:px-12 rounded-l-[10px]">Add</button>
                      <button onClick={() => openRemoveModal(item)} className="bg-[#292c3a] hover:bg-[#50577e99] text-[12px] sm:text-[14px] border border-[#333940] px-4 py-12 sm:px-12 rounded-r-[10px]">Remove</button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-8 w-full lg:w-1/2">
            <div className="text-[20px]">Your borrows</div>
            {items.filter(item => item.borrowAmt > 0n).length === 0 && (
              <div className="w-full h-[77px] flex items-center justify-center rounded-[10px] p-6 border border-[#333940] bg-[#20222480] group relative z-10 overflow-hidden transition-transform ease-in-out hover:scale-[1.01]">
                Nothing borrowed yet
              </div>
            )}
            {items.filter(item => item.borrowAmt > 0n).map((item, index) => (
              <div key={index} className="w-full rounded-[10px] p-6 border border-[#333940] bg-[#20222480] group relative z-10 overflow-hidden transition-transform ease-in-out hover:scale-[1.01]">
                <div className="flex">
                  <div
                    className="grow w-full items-center grid grid-cols-4"
                  >
                    <div className="flex gap-8 items-center px-8 py-10">
                      <img src={getIcon(item.chainId, item.asset)} className="" />
                      <span className="uppercase sm:block hidden">{item.asset}</span>
                    </div>
                    <div className="flex flex-col gap-2 items-start px-8 py-10">
                      <span className="text-[10px] text-[#bcbdbf]">Debt</span>
                      <span className="">{formatAmount(item.borrowAmt, item.decimals, 2, true)}</span>
                      {item.borrowAmt > 0n && <span className="text-[10px] text-[#bcbdbf]">${formatAmount(item.borrowAmtUSD, 8, 2, true)}</span>}
                    </div>
                    <div className="flex flex-col gap-2 items-start px-8 py-10">
                      <span className="text-[10px] text-[#bcbdbf]">APY</span>
                      <span className="">{formatAmount(item.apr, 2, 2, true)}%</span>
                    </div>
                    <div className="flex px-8 py-10 justify-center">
                      <button onClick={() => openRepayModal(item)} className="bg-[#292c3a] hover:bg-[#50577e99] text-[12px] sm:text-[14px] border border-[#333940] px-4 py-12 sm:px-12 rounded-[10px]">Repay</button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="text-[20px]">Assets to Borrow</div>
            {items.map((item, index) => (
              <div key={index} className="w-full rounded-[10px] p-6 border border-[#333940] bg-[#20222480] group relative z-10 overflow-hidden transition-transform ease-in-out hover:scale-[1.01]">
                <div className="flex">
                  <div
                    className="grow w-full items-center grid grid-cols-4"
                  >
                    <div className="flex gap-8 items-center px-8 py-10">
                      <img src={getIcon(item.chainId, item.asset)} className="" />
                      <span className="uppercase sm:block hidden">{item.asset}</span>
                    </div>
                    <div className="flex flex-col gap-2 items-start px-8 py-10">
                      <span className="text-[10px] text-[#bcbdbf]">Available</span>
                      <span className="">{formatAmount(item.amount, item.decimals, 2, true)}</span>
                      {item.amount > 0n && <span className="text-[10px] text-[#bcbdbf]">${formatAmount(item.amountUSD, 8, 2, true)}</span>}
                    </div>
                    <div className="flex flex-col gap-2 items-start px-8 py-10">
                      <span className="text-[10px] text-[#bcbdbf]">APY</span>
                      <span className="">{formatAmount(item.apr, 2, 2, true)}%</span>
                    </div>
                    <div className="flex px-8 py-10 justify-center">
                      <button onClick={() => openBorrowModal(item)} className="bg-[#292c3a] hover:bg-[#50577e99] text-[12px] sm:text-[14px] border border-[#333940] px-4 py-12 sm:px-12 rounded-[10px]">Borrow</button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
